import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from '@/utils/axios'

Vue.prototype.$axios = axios

import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

// 引入bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';

// bootstrap图标库
import 'bootstrap-icons/font/bootstrap-icons.css'

import '@/assets/custom.scss';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')