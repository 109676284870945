import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import VueCookies from 'vue-cookies'

Vue.use(Vuex)
Vue.use(VueCookies)

export default new Vuex.Store({
  state: {
    isFromWeChat: false, //判断是否微信网页
    orderIds: '', //订单ID
    isLoggedin: false, //是否登录
    token: '', //用户token
  },
  getters: {},
  mutations: {
    setIsFromWeChat(state, isFromWeChat) {
      state.isFromWeChat = isFromWeChat;
    },
    setOrderId(state, orderIds) {
      state.orderIds = orderIds;
    },
    setLoginStatus(state, status) {
      state.isLoggedin = status
    },
    setToken(state, token) {
      state.token = token
      Vue.$cookies.set('_token', token, '30d') // 设置 cookie 有效期为一个月
    }
  },
  actions: {},
  modules: {
    user
  }
})