import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import Order from '../views/orderPage/order.vue';
import myOrder from '../views/orderPage/myOrder.vue';
import createOrder from '../views/orderPage/createOrder.vue';
import fillInfo from '../views/orderPage/fillInfo.vue';
import Payments from '../views/paymentsPage/payments.vue';
import Login from '../views/page/login.vue';
import Examine from '../views/orderPage/examine.vue';
import Detailinfo from '../views/orderPage/detailInfo.vue';
import Serve from '../views/page/serve.vue';
import Agreement from '../views/page/agreement.vue';
import Statement from '../views/page/statement.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/order',
    // redirect: "/order/myorder",
    component: Order,
    // children: [
    //   {
    //     path: "myorder",
    //     component: myOrder
    //   },
    //   {
    //     path: "createorder",
    //     component: createOrder
    //   },
    //   {
    //     path: "fillinfo",
    //     component: fillInfo
    //   }
    // ]
  },
  {
    path: '/myorder',
    name: 'myorder',
    // component: () => import("@/views/paymentsPage/payments")
    component: myOrder
  },
  {
    path: '/createorder',
    name: 'createorder',
    // component: () => import("@/views/paymentsPage/payments")
    component: createOrder
  },
  {
    path: '/fillinfo',
    name: 'fillinfo',
    // component: () => import("@/views/paymentsPage/payments")
    component: fillInfo
  },
  {
    path: '/payments',
    name: 'payments',
    // component: () => import("@/views/paymentsPage/payments")
    component: Payments
  },
  {
    path: '/examine',
    name: 'examine',
    component: Examine
  },
  {
    path: '/detailinfo',
    name: 'detailinfo',
    component: Detailinfo
  },
  {
    path: '/serve',
    name: 'serve',
    component: Serve
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: Agreement
  },
  {
    path: '/statement',
    name: 'statement',
    component: Statement
  },
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
//   linkActiveClass: "active"
// });

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active"
})

export default router
