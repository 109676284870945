<template>
    <div class="content-area p-4">
        <strong>本服务协议是广州华人咨询有限公司为您（以下简称“用户”）与创孵猫之间关于使用创孵猫提供的“创孵猫代备案服务”（以下简称“本服务”）所订立的有效合约。</strong>
        <br />
        <br />
        <p>
            提示：在使用本服务之前，请用户务必仔细阅读并充分理解本服务协议的所有条款。
            <strong>
                <u>本服务协议中的部分文字可能采用加粗或下划线等形式以引起用户的特别注意，其中包括但不限于限制、免责条款，以及对用户违规行为的认定和处理方式等重要条款。用户应重点关注上述内容。</u>
            </strong>如果用户对本服务协议的任何条款存在疑问，请咨询创孵猫进行解释。如果用户不同意本服务协议的任何条款，请不要进行后续操作。
        </p>
        <p>用户可以通过直接确认、接受引用本页面链接及提示遵守内容、线下签署书面协议，或以创孵猫认可且符合法律法规习惯的其他任一方式，确认接受本服务协议的全部条款。此行为将被视为用户已充分阅读、理解并同意本服务协议的内容，与创孵猫达成一致，且本服务协议对双方均具有法律约束力。</p>
        <p>用户不应以未阅读本服务协议的内容、未获得创孵猫解答的疑问等理由主张本服务协议的无效，或要求撤销/修改本服务协议的条款。</p>
        <br />
        <ul>
            <li>协议内容和效力</li>
        </ul>
        <br />
        <p>1.1 本服务协议内容包括协议正文及其涵盖的其他协议、规则，以及在创孵猫相关页面上的服务说明、价格说明、您确认同意的订购页面、订单信息等，以及创孵猫页面上已经发布或将来可能发布的与本服务相关的各类政策、规则、声明、通知、服务条款等。上述内容构成本服务协议不可分割的组成部分，并与协议正文具有同等法律效力。</p>
        <p>
            1.2 创孵猫保留根据需要不定时制订、修改本服务协议及各类规则的权利。这些变更将以页面公示的方式进行公告，创孵猫不会另行通知您。协议及规则的修改并不以您的同意或事先通知为前提。一经公布，变更后的协议和规则立即自动生效。
            <strong>
                <u>如您不同意相关变更，应立即停止使用本服务。若您继续使用本服务，即表示您接受经修订的协议和规则。</u>
            </strong>
        </p>
        <p>1.3 本服务协议的签署和履行必须通过您的创孵猫会员账号进行。因此，《创孵猫代备案服务信息收集及使用声明》等协议条款和网站政策对您具有法律约束力。这些协议和政策为确保您的权益与隐私受到适当保护，建议您仔细阅读并理解其内容。</p>
        <br />
        <ul>
            <li>定义及解释</li>
        </ul>
        <br />
        <p>除非本协议中另有定义，下列词语在本协议中均应具有以下特定涵义：</p>
        <p>
            2.1
            <strong>创孵猫服务号</strong>：是指微信公众号为：
            <strong>
                <u>广州华人咨询有限公司</u>
            </strong>的微信公众服务号。
        </p>
        <p>
            2.2 创孵猫会员账号(创孵猫账号)：是指广州华人咨询有限公司根据客户的注册行为而生成的一串数字凭证，是创孵猫会员用户在创孵猫网站上进行浏览网站、购买商品、使用服务、参加活动、发表评论等一切 活动的身份标识。注册及使用创孵猫账号，须遵守
            <strong>《创孵猫代备案服务信息收集及使用声明》</strong>。
        </p>
        <p>2.3 小程序备案(备案)：根据法律法规规定，在中华人民共和国境内从事互联网信息服务的小程序主办者，应当依照《中华人民共和国反电信网络诈骗法》《互联网信息服务管理办法》等规定履行备案手续，未履行备案手续的，不得从事小程序互联网信息服务。</p>
        <p>2.4 互联网接入服务商(接入服务商)：指为您的小程序提供互联网接入服务的深圳市腾讯计算机系统有限公司。</p>
        <br />
        <p>三、服务内容</p>
        <br />
        <p>3.1 “创孵猫代备案服务”是由创孵猫为您提供的人工协助，旨在协助您完成备案申请，将申请信息提交给接入服务商。</p>
        <p>
            3.2 本服务将由创孵猫独立提供，包括但不限于一对一的备案咨询，收集您的备案信息及材料，并代表您向接入服务商提交备案申请。此外，我们将为您提供一个平台，供您在线查看备案申请的进度。在您的创孵猫会员账号中，您可以查看由创孵猫代您提交的备案申请内容。
            <strong>
                <u>创孵猫在此过程中不会登录您的创孵猫会员账号，也绝不会要求您提供账号密码。</u>
            </strong>
        </p>
        <p>
            <strong>
                <u>3.3 为了适应业务策略的需要，创孵猫保留根据业务发展情况不时对“创孵猫备案服务”功能进行增删、更新、调整，甚至取消本服务类型的权利。具体功能变更以您使用服务时，创孵猫相关页面的展示为准。</u>
            </strong>
        </p>
        <br />
        <p>四、信息收集及授权使用</p>
        <br />
        <p>4.1 信息收集：在您使用本服务过程中，创孵猫将收集您在使用本服务时主动提供的或因为使用服务而产生的信息及材料，用以实现协助您完成向接入服务商提交备案申请的服务目的，包括：</p>
        <p>4.1.1 个人信息：主体负责人及小程序负责人的姓名、身份证件号码、身份证件原件照片、人脸图像或视频、手机号码、固定电话、联系地址、电子邮件、职业状况、手持身份证件照片等。</p>
        <p>4.1.2 单位信息：单位名称、单位证件号码、住所、通信地址、投资者或上级主管、单位证件原件照片、 手持单位证件照片等。</p>
        <p>4.1.3 产品信息：产品类型、产品账号、产品验证信息、机房所在地等。</p>
        <p>4.1.4 小程序信息：小程序名称、小程序ID、小程序服务内容、小程序语言、前置或专项审批内容类型、服务类型。</p>
        <p>4.1.5 其他信息：与您签署的各项协议等。</p>
        <p>4.2 您的以上信息将提供给创孵猫。服务过程中，创孵猫工作人员将与您联系进行具体沟通并为您提供相关人工服务。您进行后续操作的行为将被视为创孵猫及其工作人员已获得了您的同意与授权使用以上信息为您提供服务，该等授权在本服务期间持续有效。</p>
        <p>
            4.3 创孵猫将采取安全保密措施保护您提供的信息和材料。非经您同意，不向任何第三方披露、转让、使用或出售，亦不会用于备案之外的任何目的。
            <strong>
                <u>但以下情形除外：</u>
            </strong>
        </p>
        <p>4.3.1 按照法律相关规定，申请备案时需要提供给接入服务商、备案审核机关通信管理局及工信部的信息、证件及其他材料；</p>
        <p>4.3.2 非由于创孵猫的原因已经为公众所知的；</p>
        <p>4.3.3 由于创孵猫以外其他渠道被他人获知的信息，这些渠道并不受保密义务的限制；</p>
        <p>4.3.4 由于法律的适用、法院或其他国家有权机关的要求而披露的信息；</p>
        <p>4.3.5 第三方向创孵猫提起侵权投诉后，您承认投诉成立、或您无理由不做回应、或创孵猫通过谨慎 判断认为投诉成立后，投诉方为后续维权要求创孵猫提供您的信息的；</p>
        <p>4.3.6 符合《创孵猫代备案服务信息收集及使用声明》的其他可披露情形的。</p>
        <br />
        <p>五、服务使用规范</p>
        <br />
        <p>
            5.1
            <strong>
                <u>在使用本服务之前，您需以您自身名义开设创孵猫会员账号并完成实名认证。在注册账号时，您必须确保所提供的信息真实、准确、完整。若因未满足上述要求导致无法使用服务，责任由您自行承担。您的创孵猫会员账号对于使用创孵猫代备案服务至关重要，故您需妥善保管账号及密码，并独自负责账号和密码的安全性。您应理解且同意，您在创孵猫账号下的任何操作均视为您本人的行为，因此，您应对该账号下的一切交易、行为和纠纷承担相应的后果和责任。</u>
            </strong>
        </p>
        <p>
            5.2
            <strong>
                <u>您郑重承诺并确保遵守《创孵猫代备案服务协议》的所有条款，以及遵守创孵猫页面公示的备案规则。</u>
            </strong>
        </p>
        <p>5.3 您必须理解并保证：</p>
        <p>
            5.3.1 在使用本服务过程中所提供的一切信息和材料的真实性和准确性，均由您负有责任。
            <strong>
                <u>如因您的故意或过失导致信息错误，您应独立承担全部责任和后果。</u>
            </strong>
        </p>
        <p>
            5.3.2
            <strong>
                <u>您需要向创孵猫提供完整、准确、可靠的联系方式，包括但不限于手机号码、电子邮件、联系地址或其他可供联系的详细信息。如在服务过程中您的联系方式发生变化，您务必及时通知创孵猫，以确保信息和文件传达顺畅。由于联系方式不准确所导致的任何后果，均由您独自承担。</u>
            </strong>
        </p>
        <p>
            <strong>
                <u>5.3.3 若您在使用本服务时涉及第三方信息和证件，您应确保已充分告知该第三方用途，并获得其合法授权。</u>
            </strong>
        </p>
        <p>
            5.4 您有责任积极配合创孵猫提供相关服务。您同意按照创孵猫的指引，在规定的时间和要求内，及时、准确地提供并确认相关信息和材料。
            <strong>
                <u>如因您未及时、不准确地提供信息或材料，或未按照创孵猫要求修改、补正或确认信息和材料，您需自行承担责任及后果（包括但不限于办理时间延期、申请被驳回等）。创孵猫有权终止本次服务。</u>
            </strong>
        </p>
        <p>5.5 您理解并保证：</p>
        <p>
            5.5.1 在使用本服务过程中，您应始终遵守国家法律、法规等相关规定，不得违反社会公共利益或道德规范，不得侵害他人的合法权益，同时，不得违反本服务协议的约定。
            <strong>
                <u>如您违反上述承诺，创孵猫有权随时终止本服务，您需以自己名义独立承担全部法律责任，并确保创孵猫免受任何损失。如因您使用本服务的行为给创孵猫造成损失，您应承担全部赔偿责任。</u>
            </strong>
        </p>
        <p>
            5.5.2 创孵猫仅为您提供咨询建议，并代为向接入服务商递交您的申请材料。这一行为不得视为知域互联对您所提供材料内容的认可、保证或担保。
            <strong>
                <u>您应对所提供的材料内容负完全独立的责任。如材料内容不真实、不准确，或存在其他不当内容，您需独立承担一切法律责任，并保证创孵猫不因此被任何第三方追究责任或受到任何其他损害。如此造成创孵猫损失，您需承担全部赔偿责任。</u>
            </strong>
        </p>
        <p>
            5.6 创孵猫不对您使用本服务的意图进行审查，但您所采取的行动不得使创孵猫陷入不利或纠纷之中。
            <strong>
                <u>如果因您使用本服务的行为给创孵猫造成损失，您需承担相应的责任。</u>
            </strong>
        </p>
        <br />
        <p>六、服务费用</p>
        <br />
        <p>6.1 服务费用按照创孵猫订购页面所展示的最终价格为准，显示的价格已包含税金。您需根据订单价格进行支付，此费用已涵盖您向创孵猫支付的全部服务费用。</p>
        <p>6.2 您可以基于已支付的服务费用向创孵猫申请相应金额的发票。</p>
        <p>6.3 创孵猫将在创孵猫的相关页面公布有关收费标准和缴费要求，并保留根据业务需要、市场变化等原因进行上述标准和要求调整的权利。根据当时的市场状况和自身经营政策等因素，创孵猫也可能会制定不同的服务收费标准、优惠和促销政策等。</p>
        <p>
            6.4
            <strong>
                <u>若您对创孵猫订购页面显示的本服务价格、收费标准、费用结算等内容存在异议，应及时书面通知创孵猫，并停止使用创孵猫备案服务。否则，将被视为您认可创孵猫订购页面显示的价格、收费标准和结算事项，双方应根据此进行费用的结算和支付。</u>
            </strong>
        </p>
        <p>6.5 创孵猫可能会定期或不定期推出优惠活动或政策。您需理解并确认：</p>
        <p>6.5.1 此类优惠活动或政策均为创孵猫在正常服务价格之外提供的一次性特别优惠，创孵猫不保证您一定可以享受这些特别优惠。</p>
        <p>6.5.2 在优惠活动或优惠政策期间购买本服务的用户可以享受特别优惠，但已经购买的服务不会因此获得差价退还。</p>
        <p>6.5.3 通常情况下，除非创孵猫明确书面说明，多种优惠不可同时叠加适用。</p>
        <p>
            6.6
            <strong>
                <u>您同意，因收费标准调整等引发的任何纠纷，除非法律有明确规定，否则创孵猫保留免于诉讼或仲裁的权利。</u>
            </strong>
        </p>
        <br />
        <p>七、创孵猫服务承诺</p>
        <br />
        <p>7.1 创孵猫将独立为您提供本协议约定的服务内容，协助您完成小程序备案申请提交至接入服务商的过程。</p>
        <p>
            7.2 您理解并同意，
            <strong>
                <u>在任何情况下，创孵猫均不会对备案通过接入服务商复审做出保证。备案是否通过接入服务商复审取决于您的备案申请材料是否符合法律法规政策和《创孵猫代备案服务协议》的相关规定，并由接入服务商审查后决定。创孵猫仅代表您向接入服务商提交备案申请，不以任何明示或暗示的方式保证您的备案申请一定会通过接入服务商初审。</u>
            </strong>对于备案申请是否通过接入服务商初审，创孵猫不承担法律规定之外的责任。
        </p>
        <p>
            7.3 您理解并同意，在任何情况下，
            <strong>
                <u>创孵猫均不会对备案成功做出保证。备案是否成功取决于您的备案申请材料是否符合法律法规政策的相关规定，并由各省、自治区、直辖市通信管理局依法审查后决定。创孵猫仅代表您向接入服务商提交备案申请，不以任何明示或暗示的方式保证您的备案一定会成功或持续有效。</u>
            </strong>对于备案是否成功，或备案成功后是否持续有效，创孵猫不承担法律规定之外的责任。
        </p>
        <p>
            7.4 创孵猫有权对您提供的信息和材料进行审核，并要求您进行修改、补正或确认。在您未按要求进行修改、补正或确认之前，创孵猫不会向接入服务商提交您的备案申请。如果您未能在规定时间内按要求进行修改、补正或确认，创孵猫有权退回您的备案申请并终止本次服务。
            <strong>
                <u>如果因为您提供的信息或材料不符合要求，或未能及时进行修改、补正或确认，由您自行承担全部责任和后果。</u>
            </strong>
        </p>
        <p>
            7.5 您理解并同意，
            <strong>
                <u>创孵猫有权根据审慎判断，对存在恶意使用本服务、扰乱业务秩序、多次违法违规等行为的用户账号或其关联账号采取限制使用本服务、冻结账号等措施。</u>
            </strong>
        </p>
        <br />
        <ul>
            <li>知识产权</li>
        </ul>
        <br />
        <p>8.1 在本服务协议项下一方向另一方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于 提供一方或其合法权利人所有；除提供方或合法权利人明示同意外，另一方无权复制、传播、转让、许可 或提供他人使用上述知识成果，否则应承担相应的责任。</p>
        <p>8.2 您应保证提交给创孵猫的信息和材料、对本服务的使用以及使用本服务所产生的成果，未侵犯任何 第三方的合法权益。创孵猫应保证向您提供的服务未侵犯任何第三方的合法权益。</p>
        <p>8.3 如果第三方机构或个人对您使用本服务过程中的知识产权的归属提出质疑或投诉，您和创孵猫均有责任出具相关知识产权证明材料，并配合对方的相关投诉处理工作。对于因此引起的索赔、诉讼或可能向 其提起诉讼，违约方应负责解决，承担费用和损失，以及使另一方免责。</p>
        <br />
        <ul>
            <li>不可抗力及责任承担</li>
        </ul>
        <br />
        <p>9.1 因不可抗力或者其他意外事件，使得本服务履行不可能、不必要或者无意义的，遭受不可抗力、意外 事件的一方不承担责任。“不可抗力、意外事件”是指不能预见、不能克服并不能避免且对一方或双方当事 人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动 乱、政府行为、电信主干线路中断等。</p>
        <p>
            9.2
            <strong>
                <u>鉴于互联网的特殊性，黑客攻击、互联网连通中断或者系统故障、电信部门原因造成的连通中断等非创孵猫原因给您造成的损失，除另有生效法律文书规定外，创孵猫不承担责任。</u>
            </strong>
        </p>
        <p>
            9.3
            <strong>
                <u>创孵猫秉承着专业谨慎的态度为您提供服务，若由于创孵猫 的过错造成本服务无法履行，知域 互联的责任上限为返还您已支付的本次服务的费用。 创孵猫 不对您的其他直接或间接损失、商机的 消失、利润的损失、侵权、其他无形损失及基于您与他人签署的合同而遭受的损失承担责任。</u>
            </strong>
        </p>
        <p>
            9.4
            <strong>
                <u>您应承担违反本服务协议而给创孵猫造成的损失。创孵猫 未对违约采取措施不能成为您违约或 者抗辩的理由和解释。</u>
            </strong>
        </p>
        <p>9.5 本服务协议对责任承担另有规定的，从其规定。</p>
        <br />
        <p>十、适用法律与争议解决</p>
        <br />
        <p>10.1 本服务协议的订立、执行、解释及争议解决均应依据中华人民共和国内地（大陆）地区的法律。</p>
        <p>10.2 您明确理解并同意，当您与创孵猫之间因创孵猫为您提供的创孵猫备案服务而产生争议时，双方应首先通过友好协商的方式解决。</p>
        <p>
            10.3
            <strong>
                <u>若协商未能达成一致，双方同意将争议提交至创孵猫主要经营地具有管辖权的人民法院进行诉讼解决。</u>
            </strong>
        </p>
        <br />
        <p>十一、其他</p>
        <br />
        <p>11.1 创孵猫有权在提前通知您的情况下，将本服务协议的全部或部分权利义务转移给创孵猫的关联公司。</p>
        <p>11.2 若本服务协议与双方先前签署的有关条款或创孵猫的相关陈述存在不一致或抵触之处，应以本服务协议为准。</p>
        <p>11.3 若本服务协议的任一条款被视为废止、无效或不可执行，该条款应视为可分割的，且不影响本服务协议其余条款的有效性和可执行性。</p>
        <p>11.4 本服务协议中关于保证、保密、知识产权、违约责任、法律适用及争议解决等条款，不因本服务协议的终止而失效。</p>
    </div>
</template>

<script>
export default {
    mounted() {
        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
}
</script>

<style>
</style>