<template>
    <div>
        <div class="container-fluid bg-white1 px-0">
            <div class="page-title text-center">备案信息列表</div>
            <div class="user-info">
                <div class="content">
                    <div class="user-img">
                        <img src="@/assets/img/else/user-img.jpg" alt />
                    </div>
                    <div class="info">
                        <div class="user-name">
                            {{userName}}
                            <img src="@/assets/img/else/arrow-right.png" alt />
                        </div>
                        <p class="user-id">账号ID：{{userName}}</p>
                    </div>
                </div>
            </div>
            <!-- <button type="button" @click="goExm('examine')">exm</button>
            <button type="button" @click="goExm('detailinfo')">详情</button>
            <button type="button" @click="goExm('serve')">备案服务</button>-->
            <!-- 表单搜索 -->
            <!-- <form class="fmadmin" @submit.prevent="searchList">
                <div class="row mb-3 row-cols-sm-auto g-3 align-items-center">
                    <div class="col-12">
                        <vue-datepicker-local v-model="begin" format="YYYY-MM-DD" placeholder="开始时间"></vue-datepicker-local>
                    </div>
                    <div class="col-12">
                        <vue-datepicker-local v-model="end" format="YYYY-MM-DD" placeholder="结束时间"></vue-datepicker-local>
                    </div>
                    <div class="col-12">
                        <input type="text" class="form-control" name="订单号" placeholder="订单号" v-model="order" />
                    </div>
                    <div class="col-12">
                        <input type="text" class="form-control" name="主体" placeholder="主体" v-model="subject" />
                    </div>
                    <div class="col-12">
                        <input type="text" class="form-control" name="账号" placeholder="账号" v-model="app" />
                    </div>
                    <div class="col-12">
                        <input type="text" class="form-control" name="小程序名称" placeholder="小程序名称" v-model="user" />
                    </div>
                    <div class="col-12">
                        <input type="text" class="form-control" name="keyword" placeholder="其他关键词" />
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-outline-primary">搜索</button>
                    </div>
                </div>
            </form>-->
            <!-- 搜索 -->
            <div class="search-area">
                <div class="search-box">
                    <input type="text" placeholder="请输入搜索信息内容" v-model="searchVal" />
                    <button type="button" @click="searchList">
                        <img src="@/assets/img/else/search.png" alt />搜索
                    </button>
                </div>
            </div>
            <!-- 我的订单列表 -->
            <div class="order-box">
                <div class="order-card mb-3" v-for="item in orderArray" :key="item.id">
                    <div class="list-body">
                        <div class="order-id">
                            <span>订单号</span>
                            {{item.order_number}}
                        </div>
                        <div>
                            <span>账号:</span>
                            {{item.user_name}}
                        </div>
                        <div>
                            <span>小程序主体:</span>
                            {{item.type_name}}
                        </div>
                        <div>
                            <span>小程序名称:</span>
                            {{item.app_name}}
                        </div>
                        <div>
                            <span>小程序ID:</span> 
                            <template v-if="item.detail">{{item.detail.app_id}}</template>
                            <template v-else>&nbsp;待填写</template>
                        </div>
                        <div>
                            <span>小程序备案号:</span>
                            <template v-if="item.detail">{{item.detail.app_icp_number}}</template>
                            <template v-else></template>
                        </div>
                        <div class="d-flex align-items-center">
                            <span>状态：</span>
                            <div class="tx status-box d-flex align-items-center" v-if="item.status != 4">
                                <img src="@/assets/img/else/tx.png" alt />
                                <span class>{{item.status_text}}</span>
                            </div>
                            <div class="accomplish status-box d-flex align-items-center" v-if="item.status == 4">
                                <img src="@/assets/img/else/accomplish.png" alt />
                                <span class>{{item.status_text}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-footer">
                        <div class="time">
                            <span>下单时间:</span>
                            {{item.created_at | formatDate}}
                        </div>
                        <div class="examine-btn" @click.prevent="goPage(item.id, item.status)">查看订单</div>
                    </div>
                </div>
            </div>
            <div class="all-num">
                <span>总{{orderArray.length}}条</span>
            </div>
            <!-- 创建新备案 -->
            <p class="btn-box mb-0">
                <router-link to="/createorder" class="w-100 beian-btn">申请新的备案</router-link>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            begin: '',
            end: '',
            order: '',
            subject: '',
            app: '',
            user: '',
            orderArray: [],
            searchVal: '',
            userName: '',
        }
    },
    methods: {
        // 获取订单列表
        getList() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/order/list', {
                    token: _token,
                })
                .then((res) => {
                    console.log('res', res)
                    if (res.data.code == 200) {
                        this.orderArray = res.data.data.data.reverse()
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
        // 搜索备案
        searchList() {
            console.log('搜索')
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/order/list', {
                    token: _token,
                    begin: this.begin,
                    end: this.end,
                    order: this.order,
                    subject: this.subject,
                    app: this.app,
                    user: this.user,
                })
                .then((res) => {
                    console.log('res', res)
                    if (res.data.code == 200) {
                        this.orderArray = res.data.data.data.reverse()
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
        //跳转相应页面
        goPage(i, status) {
            if (status == 0) {
                // 0未支付，跳转支付页
                localStorage.setItem('orderIds', i) // 订单id存储
                this.$router.push({ name: 'payments', params: { id: i } })
            } else if (status == 1) {
                // 1已支付待填写，跳转信息填写页
                this.$router.push({ path: '/fillinfo', query: { id: i } })
            } else if (status == 2) {
                // 2已填写待验证，跳转认证页
                this.$router.push({ path: '/examine', query: { id: i } })
            } else if (status == 3) {
                // 3审核中，跳转详情页
                this.$router.push({ path: '/detailinfo', query: { id: i } })
            } else if (status == 4) {
                // 4已完成，跳转...（待定）
                this.$router.push('/myorder')
            }
        },
        goExm(path) {
            this.$router.push({ path: `/${path}`, query: { id: 41 } })
            if (path == 'serve') {
                this.$router.push(`/${path}`)
            }
        },
    },
    filters: {
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString)
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        },
    },
    mounted() {
        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
    created() {
        // 调用一次订单列表
        this.getList()

        let user = localStorage.getItem('mfm_user')
        this.userName = user
    },
    // //组件路由守卫判断用户是否登录
    // beforeRouteEnter(to, from, next) {

    //     // 获取cookie里的token
    //     let _token = this.$cookies.get('mfm_token')

    //     //判断是否存在登录信息
    //     if (_token) {
    //         // 说明用户已登录
    //         this.$store.commit('setLoginStatus', true)
    //         this.$store.commit('setToken', _token)
    //         next()
    //     } else {
    //         // 用户未登录，重定向至登录页
    //         next({ path: '/login' })
    //     }
    // },
}
</script>

<style lang="scss" scoped>
.bg-white1 {
    background-color: #f8f8f8;
}

.page-title {
    background: linear-gradient(to bottom right, #ffb86c, #ff7b1e);
    height: 140px;
    color: #fff;
    font-size: 20px;
    letter-spacing: 2px;
    padding-top: 40px;
    font-weight: bold;
}

.user-info {
    padding: 0 18px;
    margin-top: -50px;

    .content {
        display: flex;
        align-items: center;
        padding: 20px 12px;
        border-radius: 5px;
        background-color: #fff;

        .user-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .info {
            flex-grow: 1;

            p {
                margin-bottom: 0;
            }

            .user-name {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                font-weight: bold;
                color: #202020;
            }

            .user-id {
                font-size: 12px;
                color: #6f6d6d;
                margin-top: 2px;
            }
        }
    }
}

.search-area {
    padding: 0 18px;
    margin: 14px 0 24px;

    .search-box {
        display: flex;
        padding: 10px 12px 10px 0;
        background-color: #fff;

        input {
            border: none;
            outline: none;
            flex-grow: 1;
            font-size: 12px;
            padding-left: 18px;

            &::placeholder {
                color: #9d9d9d;
            }
        }

        button {
            background-color: #ffa842;
            border-radius: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #fff;
            border: none;
            padding: 8px 18px;
            letter-spacing: 1px;

            img {
                margin-right: 4px;
            }
        }
    }
}

.order-box {
    padding: 0 18px;

    .order-card {
        background-color: #fff;
        border-radius: 3px;

        .list-body {
            padding: 20px 16px 10px;

            > div {
                color: #313131;
                font-size: 14px;
                margin-bottom: 8px;

                &.order-id {
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;
                    margin-bottom: 14px;
                }

                .status-box {
                    img {
                        margin-right: 4px;
                    }

                    span {
                        font-size: 12px;
                    }
                }

                .tx {
                    color: #ffa842;
                }

                .accomplish {
                    color: #38c98a;
                }
            }
        }

        .list-footer {
            border-top: 1px solid #f8f8f8;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 16px;
            box-sizing: border-box;

            .time {
                font-size: 12px;
                color: #898989;
            }

            .examine-btn {
                border: 1px solid #f9902d;
                border-radius: 22px;
                font-size: 14px;
                color: #f9902d;
                padding: 4px 16px;
                flex-shrink: 0;
            }
        }
    }
}

.all-num {
    text-align: right;
    font-size: 12px;
    color: #595959;
    padding-right: 18px;
    padding-top: 6px;
    padding-bottom: 106px;
}

.btn-box {
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 100%;

    .beian-btn {
        display: block;
        background-color: #ffa842;
        color: #fff;
        text-decoration: none;
        text-align: center;
        padding: 16px 0;
        font-weight: bold;
        letter-spacing: 2px;
    }
}
</style>