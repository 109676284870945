<template>
    <div class="bg-box">
        <div class="top-bg">
            <div class="bg"></div>
            <div class="status-title">
                <img src="@/assets/img/else/clock.png" alt />
                <span>待支付</span>
            </div>
            <div class="count-down">
                请在
                <span>{{minute}}分{{second}}秒</span>内完成支付哦
            </div>
        </div>
        <div class="pd-box">
            <div class="product-title">
                <div class="title-box">
                    <div class="title">产品服务：{{detailInfo.goods_name}}</div>
                    <div class="num">1次</div>
                </div>
                <div class="price-box">
                    <div class="title">应付金额：</div>
                    <div class="price">
                        ￥
                        <span>{{price}}</span>
                    </div>
                </div>
            </div>
            <div class="order-info">
                <div class="list">
                    <div class="title">下单时间</div>
                    <div class="content">{{detailInfo.created_at | formatDate}}</div>
                </div>
                <div class="list">
                    <div class="title">订单编号</div>
                    <div class="content">{{detailInfo.order_number}}</div>
                </div>
                <div class="list">
                    <div class="title">支付方式</div>
                    <div class="content">微信</div>
                </div>
                <div class="list">
                    <div class="title">交易流水号</div>
                    <div class="content">{{detailInfo.order_number}}</div>
                </div>
            </div>
        </div>
        <!-- <button type="button" class="zf-btn" v-if="isFromWeChat == true" @click="wxZf">立即支付</button> -->
        <button type="button" class="zf-btn" @click="wxZf">立即支付</button>

        <button type="button" class="back-btn" @click="goList">返回列表</button>

        <!-- 支付失败模态框 -->
        <div class="mark_bg" v-if="isModel">
            <div class="content_box">
                <div class="tip_text">
                    支付超时
                    <br />请重新进入页面进行支付...
                </div>
                <div class="btn_box">
                    <div class="go_btn btn btn-primary" @click="goList">确定</div>
                </div>
            </div>
        </div>

        <!-- 支付成功模态框 -->
        <div class="success_modal" v-if="isSuccess">
            <div class="content_box">
                <img src="@/assets/img/else/pass.png" alt />
                <div class="tip_text">支付成功</div>
                <div class="btn_box">
                    <button type="button" class="btn btn-primary go_btn" @click="goPage('fillinfo')">信息填写</button>
                    <button type="button" class="go_affirm" @click="goList">返回列表</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            orderId: '',
            qrCode: '',
            order_number: '',
            price: '',
            paymentStatus: 500, //默认500
            isPayment: false,
            paymentMsg: '',
            timer: '', //定时器
            isModel: false, //支付失败模态框
            isSuccess: false, //支付成功模态框
            code: '', //code码
            openId: '', //openid
            zfData: '', //支付数据
            detailInfo: '', //订单详情
            countdown: 900, // 倒计时总秒数，这里设置为15分钟的秒数
        }
    },
    methods: {
        // 调用查询接口
        inquire() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/pay/search', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('res', res)
                    this.paymentStatus = res.data.code
                })
        },

        //倒计时并查询支付状态
        startCountdown() {
            this.timer = setInterval(() => {
                this.countdown--
                // 调用接口查询支付状态
                this.inquire()

                // 获取当前返回的支付状态
                let paymentStatus = this.paymentStatus

                console.log('当前支付状态码', paymentStatus)

                if (paymentStatus == 200 && this.countdown > 0) {
                    // 支付成功，清除定时器，退出循环，显示成功弹框
                    clearInterval(this.timer)
                    this.isSuccess = true
                } else if (this.countdown == 0) {
                    // 倒计时结束，支付未成功，清除定时器，并显示失败弹框
                    clearInterval(this.timer)
                    this.isModel = true
                }
            }, 1000)
        },

        //跳转订单列表
        goList() {
            clearInterval(this.timer)
            this.isModel = false
            this.isSuccess = false
            this.$router.push({ path: '/myorder' })
        },

        //跳转页面
        goPage(path) {
            // 跳转信息填写页
            clearInterval(this.timer)
            this.isSuccess = false
            this.$router.push({ path: `/${path}`, query: { id: this.orderId } })
        },

        // 微信支付
        wxZf() {
            if (!this.openId) {
                alert('500：获取openid失败')
                return false
            }
            const that = this
            let _token = this.$cookies.get('mfm_token')
            let orderIds = localStorage.getItem('orderIds')
            console.log('_token', _token)
            console.log('订单号', orderIds)
            console.log('获取数据openid', this.openId)

            this.$axios
                .post('/api/pay/jsapi', {
                    token: _token,
                    order: orderIds,
                    openid: this.openId,
                })
                .then((res) => {
                    console.log('jsapiRes', res)
                    console.log('jsapiData', res.data.data)
                    if (res.data.data) {
                        if (typeof WeixinJSBridge == 'undefined') {
                            if (document.addEventListener) {
                                document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady, false)
                            } else if (document.attachEvent) {
                                document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady)
                                document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady)
                            }
                        } else {
                            that.onBridgeReady(res.data.data)
                        }
                    }
                })
        },

        // 调用微信支付
        onBridgeReady(options) {
            console.log('支付参数', options)
            let orderIds = localStorage.getItem('orderIds')

            WeixinJSBridge.invoke(
                'getBrandWCPayRequest',
                {
                    appId: options.appId, //公众号ID，由商户传入
                    timeStamp: options.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: options.nonceStr, //随机串
                    package: options.package,
                    signType: options.signType, //微信签名方式：
                    paySign: options.paySign, //微信签名
                },
                function (res) {
                    WeixinJSBridge.log(res.err_msg)
                    if (res.err_msg == 'get_brand_wcpay_request:ok') {
                        // alert('支付成功！')
                        this.$router.push({ path: '/fillinfo', query: { id: orderIds } })
                    } else {
                        // alert('支付失败：' + res.err_code + res.err_desc + res.err_msg)
                    }
                }
            )
        },

        //截取网址参数方法,用于获取微信code
        getQueryVariable(variable) {
            var query = window.location.search.substring(1)
            var vars = query.split('&')
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=')
                if (pair[0] == variable) {
                    return pair[1]
                }
            }
            return false
        },

        // 获取code
        getCode() {
            //获取token
            let _token = this.$cookies.get('mfm_token')

            // 获取本地存储的orderIds
            let orderIds = localStorage.getItem('orderIds')
            console.log('orderIds', orderIds)

            let code = this.getQueryVariable('code') //获取微信返回code
            let appid = 'wx9dca60f31cd877b0'
            let redirect_uri = window.location.href //回调地址为当前页面
            // redirect_uri = encodeURIComponent(redirect_uri)
            let url1 = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
            // let url1 = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9dca60f31cd877b0&redirect_uri=http://beian.cfumao.com/payments&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`

            // this.$axios.get('/test').then((res) => {
            //     console.log(res);
            // })

            if (!code) {
                console.log('无code')
                window.location.href = url1 //如果没有code值，则跳转微信接口获取code
            } else {
                this.code = code
                console.log('已获取code', this.code)

                let that = this
                // 通过微信返回的code获取用户openid
                this.$axios
                    .post('/api/pay/get/openid', {
                        token: _token,
                        code: that.code,
                    })
                    .then((res) => {
                        console.log('getOpenid', res.data.data)
                        that.openId = res.data.data
                        console.log('openid', that.openId)
                    })
            }
        },

        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('resDetails', res)
                    if (res.data.code == 200) {
                        this.detailInfo = res.data.data
                        this.order_number = res.data.data.order_number
                        this.price = (res.data.data.price * 100) / 100
                    }
                    if (res.data.code == 500) {
                        console.log(res.data.msg)
                    }
                })
        },
    },
    computed: {
        ...mapState(['isFromWeChat']), // 使用 mapState 辅助函数获取存储在vuex里的判断信息
        //分
        minute() {
            return String(Math.floor(this.countdown / 60)).padStart(2, '0')
        },
        //秒
        second() {
            return String(this.countdown % 60).padStart(2, '0')
        },
    },
    filters: {
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString)
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        },
    },
    created() {
        // 获取订单ID
        let orderIds = localStorage.getItem('orderIds')
        this.orderId = orderIds
        console.log('当前订单id为：', orderIds)

        // let _token = this.$cookies.get('mfm_token')
        // this.$axios
        //     .post('/api/pay/get/openid/v2', {
        //         token: _token,
        //     })
        //     .then((res) => {
        //         console.log('test', res)
        //     })

        // 有订单id时执行
        if (this.orderId) {
            this.getDetails()
        }

        // 有订单id并且不是微信网页时执行
        if (this.orderId && !this.isFromWeChat) {
            let _token = this.$cookies.get('mfm_token')

            // 获取支付二维码
            this.$axios
                .post('/api/pay/pc', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('qrcoderes', res)
                    if (res.data.code == 200) {
                        console.log(res.data.msg)
                        this.qrCode = res.data.data.url.code_url
                    }
                    if (res.data.code == 500) {
                        console.log(res.data.msg)
                        this.isPayment = true
                        this.paymentMsg = res.data.msg
                    }
                })
        }

        // 本地存储里有订单id时才执行
        if (orderIds && orderIds != 'undefined') {
            // 如果是微信网页才会执行
            if (this.isFromWeChat) {
                this.getCode()
            }
        }
    },
    mounted() {
        // 有订单id
        if (this.orderId) {
            // 调用查询支付
            // this.startChecking()
            this.startCountdown()
        }
    },
    // 如果有定时器,离开页面时清除定时器
    beforeDestroy() {
        // 在组件销毁前清除定时器
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
}
</script>

<style lang="scss" scoped>
.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
}

.mark_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;

    .content_box {
        background-color: #fff;
        width: 80%;
        border-radius: 10px;

        .tip_text {
            padding: 30px 20px;
            text-align: center;
            border-bottom: 1px solid #999;
        }

        .btn_box {
            padding: 16px 0;
            display: flex;
            justify-content: center;

            .go_btn {
                width: 40%;
                text-align: center;
                padding: 8px;
            }
        }
    }
}

.top-bg {
    position: relative;
    overflow: hidden;
    height: 240px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 800px;
        height: 800px;
        border-radius: 50%;
        background: linear-gradient(to right, #ffbb70, #ff7c1f);
    }

    .status-title {
        z-index: 1;
        display: flex;
        align-items: center;

        img {
            margin-right: 8px;
        }
        span {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .count-down {
        z-index: 1;
        display: flex;
        align-items: center;

        span {
            font-weight: bold;
            font-size: 14px;
            margin: 0 5px;
        }
    }
}

.pd-box {
    padding: 0 14px;
}

.product-title,
.order-info {
    padding: 24px 22px;
}

.product-title {
    background-color: #fff;
    border-radius: 10px;
    margin-top: -80px;
    position: relative;
    z-index: 1;
    margin-bottom: 16px;

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .title {
            color: #202020;
        }
        .num {
            color: #202020;
        }
    }

    .price-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #202020;
        }
        .price {
            color: #e00707;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
}

.order-info {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    z-index: 1;

    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .title {
            color: #878787;
            font-size: 14px;
        }
        .content {
            font-size: 14px;
        }
    }
}

.zf-btn,
.back-btn {
    padding: 12px 70px;
    font-size: 18px;
    margin: 40px auto 0;
    display: block;
    border: 1px solid #ffa842;
    border-radius: 30px;
    font-size: 16px;
    color: #ffa842;
    background-color: transparent;
}

.back-btn {
    margin: 20px auto 0;
}

// 支付成功模态框
.success_modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .content_box {
        width: 90%;
        background-color: #fff;
        border-radius: 10px;
        padding: 60px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tip_text {
            text-align: center;
            margin: 18px 0 24px;
            font-weight: bold;
            font-size: 18px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                flex-basis: 36%;
                font-size: 16px;
                letter-spacing: 1px;
                border-radius: 20px;
                padding: 6px 0px;
            }

            .go_affirm {
                background-color: #fff;
                margin-left: 16px;
                border: 1px solid #c4c4c4;
            }
        }
    }
}
</style>