import axios from 'axios';

//axios.create() 创建一个axios请求对象并添加配置
const _axios = axios.create({
  baseURL: config.baseUrl //后面调用 axios.get("/abc") 自动把调用地址和 baseURL进行拼接.
  // baseURL: ""
});

// 请求拦截器
_axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 响应拦截器
_axios.interceptors.response.use(response => {
  // 处理响应数据
  return response;
}, error => {
  // 处理全局错误
  console.error('请求错误:', error);
  return Promise.reject(error);
});

export default _axios;