// store/user.js

const state = {
    username: '' // 初始值为空
}

const mutations = {
    set_usernum(state, username) {
        state.username = username
    }
}

const actions = {
    setUsernum({
        commit
    }, username) {
        commit('set_usernum', username)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}