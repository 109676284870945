<template>
    <div class="home">
        <div class="container-fluid bg-white1 px-0">
            <div class="header">
                <p class="title">创孵猫备案系统</p>
                <p class="vice-title">累计服务百万用户的专业备案系统平台</p>
                <div class="advantage">
                    <div>
                        <div class="img-box">
                            <img src="@/assets/img/home/icon1.png" alt />
                        </div>
                        <p class="li-title">极速备案</p>
                    </div>
                    <div>
                        <div class="img-box">
                            <img src="@/assets/img/home/icon2.png" alt />
                        </div>
                        <p class="li-title">通过率高</p>
                    </div>
                    <div>
                        <div class="img-box">
                            <img src="@/assets/img/home/icon3.png" alt />
                        </div>
                        <p class="li-title">专业辅导</p>
                    </div>
                    <div>
                        <div class="img-box">
                            <img src="@/assets/img/home/icon4.png" alt />
                        </div>
                        <p class="li-title">全程跟进</p>
                    </div>
                </div>
                <!-- <div class="tips-img">
                    <img src="@/assets/img/home/pic1.png" alt />
                </div> -->
            </div>
            <!-- 备案流程 -->
            <div class="beian-flow">
                <p class="title">
                    <img src="@/assets/img/home/icon5.png" alt />
                    <span>备案办理流程</span>
                    <img src="@/assets/img/home/icon5.png" alt />
                </p>
                <div class="list-box">
                    <div class="list" v-for="item,index in flowArray" :key="index">
                        <div class="top">{{index + 1}}</div>
                        <div class="list-content">
                            <p class="title">{{item.title}}</p>
                            <p class="vice-title">{{item.vice_title}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-box mb-0">
                <div class="w-100 beian-btn" @click="begin()">开始备案</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            flowArray: [
                {
                    title: '填写备案信息',
                    vice_title: '登录填写备案信息，并上传材料',
                },
                {
                    title: '验证备案类型',
                    vice_title: '填写主办单位证件号码和域名，验证所需备案类型',
                },
                {
                    title: '平台初审',
                    vice_title: '1-2个工作日内完成初审并告知结果',
                },
                {
                    title: '工信部短信核验',
                    vice_title: '提交通管局后，24小时内完成短信核验',
                },
                {
                    title: '通管局审核',
                    vice_title: '通管局在1-20个工作日内完成审核并返回结果',
                },
                {
                    title: '备案成功',
                    vice_title: '审核通过，下发小程序备案号',
                },
            ],
        }
    },
    methods: {
        begin() {
            // 获取cookie里的token
            const _token = this.$cookies.get('mfm_token')

            //判断是否存在登录信息
            if (_token) {
                // 说明用户已登录
                this.$store.commit('setLoginStatus', true)
                this.$store.commit('setToken', _token)
                this.$router.push('/myorder')
            } else {
                // 用户未登录，重定向至登录页
                this.$router.push('/login')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-white1 {
    background-color: #f8f8f8;
}

.header {
    background: url(@/assets/img/home/bg.png) no-repeat;
    background-size: 100%;
    padding: 30px 0 0;

    .title {
        color: #b36330;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 4px;
        text-align: center;
    }
    .vice-title {
        color: #cc946f;
        text-align: center;
        font-size: 14px;
        margin: 16px 0;
    }
    .advantage {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;

            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .li-title {
                color: #b36330;
                font-size: 14px;
            }
        }
    }
    .tips-img {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.beian-flow {
    margin-top: 10px;
    padding-top: 15px;

    > .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        span {
            font-weight: bold;
            font-size: 20px;
            margin: 0 20px;
        }

        img:last-child {
            transform: rotateY(180deg);
        }
    }

    .list-box {
        padding-left: 28px;

        .list {
            display: flex;
            align-items: center;
            margin: 10px 0;

            &:first-child {
                .top {
                    &::before {
                        content: none;
                    }
                }
            }

            &:last-child {
                .top {
                    &::after {
                        content: none;
                    }
                }
            }

            .top {
                width: 20px;
                height: 20px;
                line-height: 20px;
                background-color: #f8b62b;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                margin-right: 6px;
                font-weight: bold;
                font-size: 14px;
                position: relative;

                &::before,
                &::after {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 1px;
                    height: 30px;
                    content: '';
                    background-color: #ddc3b6;
                }

                &::before {
                    top: -30px;
                }

                &::after {
                    bottom: -30px;
                }
            }

            .list-content {
                height: 66px;
                background: url(@/assets/img/home/pic2.png) no-repeat;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 21px;
                flex-grow: 1;

                p {
                    margin-bottom: 0;
                }
                .title {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 4px;
                }
                .vice-title {
                    font-size: 12px;
                    color: #929292;
                }
            }
        }
    }
}

.btn-box {
    margin-top: 20px;

    .beian-btn {
        display: block;
        background-color: #ffa842;
        color: #fff;
        text-decoration: none;
        text-align: center;
        padding: 16px 0;
        font-weight: bold;
        letter-spacing: 2px;
    }
}
</style>