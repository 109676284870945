<template>
    <div class="bg-box">
        <div class="page-title">备案信息认证</div>
        <div class="area-title">订单{{detailsInfo.order_number}}</div>
        <div class="info-list" v-for="item,index in verifyArray" :key="index">
            <div class="list">
                <div class="list-title">{{item.name}}</div>
                <div class="list-content">{{item.value}}</div>
                <!-- <span class="text-danger msg1">测试提示</span> -->
            </div>
            <div class="list">
                <div class="list-title">验证码</div>
                <div class="list-content">
                    <div class="d-flex align-items-center">
                        <input :type="item.type" class="form-control verify_input" placeholder="请输入验证码" v-model="inputCode[index]" />
                        <button type="button" class="btn btn-outline-primary flex-shrink-0" @click="sendCode(item,index)" :disabled="btnStatus[index].btndisabled">{{btnStatus[index].btnstr}}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="qrcode-area bgf" v-if="isPerson">
            <div class="area-title">个人代注册扫脸认证</div>
            <div class="explain-list">
                <div class="explain">1、个人代注册扫二维码，完成操作后。 会收到一条微信服务消息，请按提示完成扫脸认证。</div>
                <div class="explain">2、完成后点击提交按钮。</div>
            </div>
            <div class="qr-code">
                <div class="code-box">
                    <img :src="statusInfo.task_qr_code" alt="无" />
                </div>
            </div>
        </div>

        <div class="qrcode-area bgf" v-if="isApplet">
            <div class="area-title">小程序管理员扫脸认证</div>
            <div class="explain-list">
                <div class="explain">1、小程序管理员扫二维码，完成操作后。 会收到一条微信服务消息，请按提示完成扫脸认证。</div>
                <div class="explain">2、完成后点击提交按钮。</div>
            </div>
            <div class="qr-code">
                <div class="code-box">
                    <img :src="statusInfo.admin_qr_code" alt="无" />
                </div>
            </div>
        </div>

        <div class="reviewing">
            <div class="btn-box">
                <button class="btn btn-primary save-btn" type="button" @click="allVerify">提交认证</button>
                <button class="draft-btn" type="button" @click="goPage('myorder')">返回</button>
            </div>
        </div>

        <!-- 模态框 -->
        <div class="mark_bg" v-if="isModel">
            <div class="content_box">
                <img src="@/assets/img/else/pass.png" alt />
                <div class="tip_text">恭喜您！认证完成</div>
                <div class="btn_box">
                    <button type="button" class="btn btn-primary go_btn" @click="goPage('home')">返回首页</button>
                    <button type="button" class="go_affirm" @click="goPage('createorder')">创建新备案</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderId: '', //订单ID
            detailsInfo: '', //详细信息
            verifyArray: [], //字段信息
            statusInfo: '', //状态信息
            isPerson: false, //个人代注册
            isApplet: false, //小程序扫脸
            inputCode: [],
            formData: [],

            btnStatus: [
                {
                    btndisabled: false,
                    btnstr: '获取验证码',
                },
                {
                    btndisabled: false,
                    btnstr: '获取验证码',
                },
                {
                    btndisabled: false,
                    btnstr: '获取验证码',
                },
            ],

            isModel: false,

            inputs: [],
        }
    },
    methods: {
        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('details', res)
                    this.detailsInfo = res.data.data
                })
        },

        // 获取验证页面字段
        getVerify() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/order/verify', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('verify', res)
                    this.verifyArray = res.data.data
                })
        },

        // 获取订单状态
        getStatus() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/order/status', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('status', res)
                    this.statusInfo = res.data.data
                    // 如果有小程序管理员授权扫脸
                    if (res.data.data.admin_qr_code) {
                        this.isApplet = true
                    }
                    // 如果有个人代注册授权扫脸
                    if (res.data.data.task_qr_code) {
                        this.isPerson = true
                    }
                })
        },

        // 发送验证码
        sendCode(info, index) {
            let _token = this.$cookies.get('mfm_token')
            console.log('info', info)

            this.$axios
                .post('/api/order/send', {
                    token: _token,
                    value: info.value,
                    type: info.type,
                })
                .then((res) => {
                    console.log('send', res)
                    console.log(res.data.msg)
                })

            this.timewait(60, index)
            this.btnStatus[index].btndisabled = true
        },

        // 获取验证码间隔时间
        timewait(t, index) {
            let _this = this
            setTimeout(function () {
                if (t >= 0) {
                    _this.btnStatus[index].btnstr = t + '秒后点击'
                    t--
                    _this.timewait(t, index)
                } else {
                    _this.btnStatus[index].btnstr = '获取验证码'
                    t = 60
                    _this.btnStatus[index].btndisabled = false
                }
            }, 1000)
        },

        // 页面统一验证
        allVerify() {
            let _token = this.$cookies.get('mfm_token')

            // 是否填写全部验证框
            let allCheck = true

            // 遍历检查是否有空的输入框
            for (let i = 0; i < this.inputCode.length; i++) {
                if (this.inputCode[i] === '' || !this.inputCode[i]) {
                    allCheck = false
                    alert('请填写完整所有验证码')
                    return
                }
            }

            if (this.inputCode.length < 3 || allCheck == false) {
                alert('请填写完整所有验证码')
                return
            }

            const result = []

            for (const [index, item] of this.verifyArray.entries()) {
                console.log('index', index)
                const newItem = { value: item.value, type: item.type, code: this.inputCode[index] }
                result.push(newItem)
            }

            // 这里的 result 就是你想要的结果
            console.log('二维数组', result)

            this.$axios
                .post('/api/order/verifyForm', {
                    token: _token,
                    form: result,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('verifyForm', res)
                    if (res.data.code == 200) {
                        this.isModel = true
                    }
                    if (res.data.code == 500) {
                        alert(res.data.msg)
                    }
                })
        },

        // 页面跳转
        goPage(path) {
            this.isModel = false
            this.$router.push(`/${path}`)
        },
    },
    mounted() {
        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
    created() {
        // 获取订单ID
        this.orderId = this.$route.query.id
        console.log('当前订单id为：', this.orderId)

        if (this.orderId) {
            this.getDetails()
            this.getVerify()
            this.getStatus()
        }
    },
}
</script>

<style lang="scss" scoped>
.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;

    > .area-title {
        font-size: 18px;
        font-weight: bold;
        padding-left: 16px;
        margin: 24px 0 14px;
    }
}

.bgf {
    background-color: #fff;
}

.page-title {
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.info-list {
    margin-bottom: 16px;

    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-bottom: 1px solid #e8e8e8;
        padding: 16px;
        font-size: 14px;

        .list-title {
            flex-basis: 40%;
        }

        .list-content {
            flex-basis: 60%;

            input {
                font-size: 14px;
                padding-left: 0;
                border: none;

                &::placeholder {
                    font-size: 14px;
                    color: #ababab;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            button {
                font-size: 12px;
                border-radius: 20px;
            }
        }
    }
}

// 二维码区域
.qrcode-area {
    padding: 20px 16px 30px;
    margin-bottom: 16px;

    .area-title {
        font-weight: bold;
        margin-bottom: 14px;
    }

    .explain-list {
        margin-bottom: 28px;

        .explain {
            color: #515151;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .qr-code {
        display: flex;
        align-items: center;
        justify-content: center;

        .code-box {
            width: 167px;

            img {
                width: 100%;
            }
        }
    }
}

// 操作按钮
.reviewing {
    padding: 24px 0 60px;

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            flex-basis: 40%;
            padding: 10px 0;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            border-radius: 6px;
            color: #fff;
        }

        .draft-btn {
            margin-left: 16px;
            background-color: #acacac;
            border: none;
        }
    }
}

// 提交后模态框
.mark_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .content_box {
        width: 90%;
        background-color: #fff;
        border-radius: 10px;
        padding: 60px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tip_text {
            text-align: center;
            margin: 18px 0 24px;
            font-weight: bold;
            font-size: 18px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                flex-basis: 36%;
                font-size: 16px;
                letter-spacing: 1px;
                border-radius: 20px;
                padding: 6px 0px;
            }

            .go_affirm {
                background-color: #fff;
                margin-left: 16px;
                border: 1px solid #c4c4c4;
            }
        }
    }
}
</style>