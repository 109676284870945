<template>
    <div class="bg-box">
        <div class="page-title bgf">申请备案</div>
        <div class="service-selection bgf">
            <div class="area-title">服务选择</div>
            <div class="list-box">
                <div :class="['service',radioVal == index + 1 ? 'active':'']" v-for="item,index in set_meal" :key="index" @click="selectMeal(index + 1,item.price)">
                    <div class="title">{{item.name}}</div>
                    <div class="vice-title" v-if="index == 0">指导用户填写备案材料</div>
                    <div class="vice-title" v-if="index == 1">1v1咨询，极速提交</div>
                    <div class="price">￥{{[item.price * 100] / 100}}/次</div>
                    <div class="ps-title">99%用户选择</div>
                    <img src="@/assets/img/else/active.png" alt class="ps-active" />
                </div>
            </div>
        </div>
        <div class="created-beian">
            <div class="area-title bgf">创建备案</div>
            <form :class="['formorder','form','needs-validation',fromClass ? 'was-validated':'']" @submit.prevent="submitForm" novalidate>
                <div class="info-area bgf">
                    <div class="info-list">
                        <label class="form-label lab">用户名称</label>
                        <div>{{user}}</div>
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">性质</label>
                        <div>
                            <select class="form-select inp" name="性质" v-model="type">
                                <option v-for="item in typeList" :key="item.id" :value="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">
                            主办单位/个人名称
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control verify_input inp" required name="主办单位或主办人名称" v-model="userName" placeholder="请填写主办单位/个人名称" />
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">
                            小程序名称
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control verify_input inp" required name="小程序名称" v-model="appName" placeholder="请填写小程序名称" />
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">推荐码</label>
                        <input type="text" class="form-control inp" placeholder="请填写推荐码" v-model="code" />
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">优惠券</label>
                        <div>
                            <select class="form-select inp" v-model="coupin" @change="selectCoupin()">
                                <option v-for="item in coupinList" :key="item.id" :value="item.detail.id">无门槛券--抵扣{{(item.detail.amount * 100) / 100}}元</option>
                            </select>
                        </div>
                    </div>
                    <div class="tips">
                        <span>温馨提示：</span>帐号名称长度为4-30个字符，一个中文字等于2个字符。请保持小程序名称的独特性以免与其他已注册小程序冲突。
                    </div>
                </div>
                <!-- <div class="card mb-3">
                    <div class="card-header">创孵猫代备案服务信息收集及使用声明</div>
                    <div class="card-body" style="max-height: 400px; overflow: auto">
                        <p>创孵猫（以下简称“我们”）在向您提供创孵猫代备案服务（以下简称“本服务”）时，会按照《创孵猫代备案服务协议》以及本声明的约定收集、使用、存储、对外提供及保护您的个人信息；超出以上约定收集您的用户信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供本服务所必要的您的信息；如您选择不提供前述信息，将会影响到您使用本服务，但不会影响您使用创孵猫网站基本功能和其他产品。</p>
                        <p>&nbsp;</p>
                        <p>在使用本服务前，请您务必仔细阅读并透彻理解本声明的全部内容，在确认充分理解并同意后方使用本服务。一旦您开始使用本服务，将被视为对本声明内容的接受和认可。</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>一、定义</p>
                        <p>&nbsp;</p>
                        <p>1.1.创孵猫服务号：指创孵猫微信服务号（名称为：广州华人咨询有限公司）。</p>
                        <p>&nbsp;</p>
                        <p>1.2.创孵猫：创孵猫代备案服务的提供方，包括广州华人咨询有限公司、上海观安信息技术股份有限公司、深圳观安信息技术有限公司。</p>
                        <p>&nbsp;</p>
                        <p>1.3.用户信息：是指您注册创孵猫网站账户，使用创孵猫代备案服务以及创孵猫为了向您提供服务，您提交的或者创孵猫收集的用户信息，具体详见本声明第二条所述的信息。</p>
                        <p>&nbsp;</p>
                        <p>二、我们如何收集您的用户信息</p>
                        <p>&nbsp;</p>
                        <p>我们会收集您在使用本服务过程中主动提供或因使用服务而产生的信息：</p>
                        <p>&nbsp;</p>
                        <p>2.1.设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。</p>
                        <p>&nbsp;</p>
                        <p>2.2.备案信息：当您使用本服务时，我们会收集您主动填写/上传的备案信息以及配合我们真实性核验过程中所提供的信息。包括：</p>
                        <p>&nbsp;</p>
                        <p>2.2.1.个人信息：主体负责人及网站负责人的姓名、身份证件号码、身份证件原件照片、人脸图像或视频、手机号码、固定电话、联系地址、电子邮件、职业状况、手持身份证件照片等；</p>
                        <p>&nbsp;</p>
                        <p>2.2.2.单位信息：单位名称、单位证件号码、住所、通信地址、投资者或上级主管、单位证件原件照片、手持单位证件照片等；</p>
                        <p>&nbsp;</p>
                        <p>2.2.3.小程序信息：小程序名称、小程序备案号、小程序服务内容、小程序语言、前置或专项审批内容类型、小程序ID、小程序备注；</p>
                        <p>&nbsp;</p>
                        <p>2.2.4.其他信息：与您签署的各项协议、备案核查的电话录音及视频等。</p>
                        <p>&nbsp;</p>
                        <p>2.3.5.您应对您的使用本服务过程中提交的备案信息的真实性、准确性负责，因您的提供的备案信息不真实、不准确而造成的全部结果及责任均由您自行承担。</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>三、我们如何使用您的用户信息</p>
                        <p>&nbsp;</p>
                        <p>为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，我们会在以下情形中使用您提交以及我们收集的用户信息：</p>
                        <p>&nbsp;</p>
                        <p>3.1. 为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知、进行备案核查时的电话/邮件通知等；</p>
                        <p>&nbsp;</p>
                        <p>3.3.2.为了代理您完成小程序备案申请的提交，我们在取得您明确授权同意的情况下，可能会代您对部分备案信息进行修改/补充。</p>
                        <p>&nbsp;</p>
                        <p>3.3.3.为了维护、改进服务，向您提供更符合您个性化需求的信息展示，我们可能将您使用本服务提供的用户信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，向您展示、推送信息和可能的商业广告，包括但不限于关于创孵猫产品的新闻以及市场活动及优惠促销信息、创孵猫合作第三方的推广信息，或其他您可能感兴趣的内容。如果您不想接收我们给您发送的商业性电子信息，您可通过短信提示回复退订或根据信息中提供的退订方式予以退订；</p>
                        <p>&nbsp;</p>
                        <p>
                            3.3.4.
                            我们可能以用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息；
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            3.3.5.
                            为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或创孵猫相关协议、规则的情况，我们可能使用您使用本服务过程中收集的用户信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施；
                        </p>
                        <p>&nbsp;</p>
                        <p>3.3.6. 如超出收集用户信息时所声称的目的，或者在超出具有直接或合理关联的范围使用用户信息前，我们会再次向您告知并征得您的明示同意。</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>四、我们如何共享、转让、公开披露您的用户信息</p>
                        <p>&nbsp;</p>
                        <p>4.1. 共享</p>
                        <p>&nbsp;</p>
                        <p>我们不会与其他组织和个人共享您的用户信息，但以下情况除外：</p>
                        <p>&nbsp;</p>
                        <p>4.1.1. 按照法律相关规定，申请备案时需要提供给备案审核机关通信管理局及工信部的信息、证件及其他材料；</p>
                        <p>&nbsp;</p>
                        <p>4.1.2.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息；</p>
                        <p>&nbsp;</p>
                        <p>4.1.3.在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；</p>
                        <p>&nbsp;</p>
                        <p>4.1.4.为了协助解决争议，某些情况下只有共享您的用户信息，才能处理您与他人的纠纷或争议，例如，第三方向我们提起侵权投诉后，您承认投诉成立、或您无理由不做回应、或我们通过谨慎判断认为投诉成立后，投诉方为后续维权要求我们提供您的信息的；</p>
                        <p>&nbsp;</p>
                        <p>4.1.5.与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。</p>
                        <p>&nbsp;</p>
                        <p>4.2. 转让</p>
                        <p>&nbsp;</p>
                        <p>我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</p>
                        <p>&nbsp;</p>
                        <p>4.2.1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；</p>
                        <p>&nbsp;</p>
                        <p>
                            4.2.2.
                            在创孵猫与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，广州华人咨询有限公司会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
                        </p>
                        <p>4.3. 公开披露</p>
                        <p>&nbsp;</p>
                        <p>我们仅会在以下情况下，公开披露您的用户信息：</p>
                        <p>&nbsp;</p>
                        <p>4.3.1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的用户信息；</p>
                        <p>&nbsp;</p>
                        <p>4.3.2. 或为保护创孵猫平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或创孵猫平台相关协议、规则披露关于您的用户信息。</p>
                        <p>&nbsp;</p>
                        <p>4.4. 共享、转让、公开披露用户信息时事先征得授权同意的例外</p>
                        <p>&nbsp;</p>
                        <p>以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：</p>
                        <p>&nbsp;</p>
                        <p>4.4.1. 与国家安全、国防安全有关的；</p>
                        <p>&nbsp;</p>
                        <p>4.4.2. 与公共安全、公共卫生、重大公共利益有关的；</p>
                        <p>&nbsp;</p>
                        <p>4.4.3. 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                        <p>&nbsp;</p>
                        <p>4.4.4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                        <p>&nbsp;</p>
                        <p>4.4.5. 您自行向社会公众公开的个人信息；</p>
                        <p>&nbsp;</p>
                        <p>4.4.6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-header">创孵猫代备案服务协议</div>
                    <div class="card-body" style="max-height: 400px; overflow: auto">
                        <p>
                            <strong>本服务协议是广州华人咨询有限公司为您（以下简称“用户”）与创孵猫之间关于使用创孵猫提供的“创孵猫</strong>
                            <strong>代</strong>
                            <strong>备案服务”（以下简称“本服务”）所订立的有效合约。</strong>
                        </p>
                        <p>
                            提示：在使用本服务之前，请用户务必仔细阅读并充分理解本服务协议的所有条款。
                            <strong>
                                <u>本服务协议中的部分文字可能采用加粗或下划线等形式以引起用户的特别注意，其中包括但不限于限制、免责条款，以及对用户违规行为的认定和处理方式等重要条款。用户应重点关注上述内容。</u>
                            </strong>如果用户对本服务协议的任何条款存在疑问，请咨询创孵猫进行解释。如果用户不同意本服务协议的任何条款，请不要进行后续操作。
                        </p>
                        <p>用户可以通过直接确认、接受引用本页面链接及提示遵守内容、线下签署书面协议，或以创孵猫认可且符合法律法规习惯的其他任一方式，确认接受本服务协议的全部条款。此行为将被视为用户已充分阅读、理解并同意本服务协议的内容，与创孵猫达成一致，且本服务协议对双方均具有法律约束力。</p>
                        <p>用户不应以未阅读本服务协议的内容、未获得创孵猫解答的疑问等理由主张本服务协议的无效，或要求撤销/修改本服务协议的条款。</p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>协议内容和效力</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                            1.1
                            本服务协议内容包括协议正文及其涵盖的其他协议、规则，以及在创孵猫相关页面上的服务说明、价格说明、您确认同意的订购页面、订单信息等，以及创孵猫页面上已经发布或将来可能发布的与本服务相关的各类政策、规则、声明、通知、服务条款等。上述内容构成本服务协议不可分割的组成部分，并与协议正文具有同等法律效力。
                        </p>
                        <p>
                            1.2
                            创孵猫保留根据需要不定时制订、修改本服务协议及各类规则的权利。这些变更将以页面公示的方式进行公告，创孵猫不会另行通知您。协议及规则的修改并不以您的同意或事先通知为前提。一经公布，变更后的协议和规则立即自动生效。
                            <strong>
                                <u>如您不同意相关变更，应立即停止使用本服务。若您继续使用本服务，即表示您接受经修订的协议和规则。</u>
                            </strong>
                        </p>
                        <p>
                            1.3
                            本服务协议的签署和履行必须通过您的创孵猫会员账号进行。因此，《创孵猫代备案服务信息收集及使用声明》等协议条款和网站政策对您具有法律约束力。这些协议和政策为确保您的权益与隐私受到适当保护，建议您仔细阅读并理解其内容。
                        </p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>定义及解释</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>除非本协议中另有定义，下列词语在本协议中均应具有以下特定涵义：</p>
                        <p>
                            2.1 创孵猫服务号：是指微信公众号为：
                            <strong>
                                <u>广州华人咨询有限公司</u>
                            </strong>的微信公众服务号。
                        </p>
                        <p>
                            2.2
                            创孵猫会员账号(创孵猫账号)：是指广州华人咨询有限公司根据客户的注册行为而生成的一串数字凭证，是创孵猫会员用户在创孵猫网站上进行浏览网站、购买商品、使用服务、参加活动、发表评论等一切
                            活动的身份标识。注册及使用创孵猫账号，须遵守
                            <strong>《创孵猫代备案服务信息收集及使用声明</strong>》。
                        </p>
                        <p>2.3小程序备案(备案)：根据法律法规规定，在中华人民共和国境内从事互联网信息服务的小程序主办者，应当依照《中华人民共和国反电信网络诈骗法》《互联网信息服务管理办法》等规定履行备案手续，未履行备案手续的，不得从事小程序互联网信息服务。</p>
                        <p>2.4互联网接入服务商(接入服务商)：指为您的小程序提供互联网接入服务的深圳市腾讯计算机系统有限公司。</p>
                        <p>&nbsp;</p>
                        <p>三、服务内容</p>
                        <p>&nbsp;</p>
                        <p>3.1 “创孵猫代备案服务”是由创孵猫为您提供的人工协助，旨在协助您完成备案申请，将申请信息提交给接入服务商。</p>
                        <p>
                            3.2
                            本服务将由创孵猫独立提供，包括但不限于一对一的备案咨询，收集您的备案信息及材料，并代表您向接入服务商提交备案申请。此外，我们将为您提供一个平台，供您在线查看备案申请的进度。在您的创孵猫会员账号中，您可以查看由创孵猫代您提交的备案申请内容。
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>在此过程中不会登录您的</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>会员账号，也绝不会要求您提供账号密码。</u>
                            </strong>
                        </p>
                        <p>
                            <strong>
                                <u>3.</u>
                            </strong>
                            <strong>
                                <u>3</u>
                            </strong>
                            <strong>
                                <u>&nbsp;为了适应业务策略的需要，</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>保留根据业务发展情况不时对</u>
                            </strong>
                            <strong>
                                <u>“创孵猫备案</u>
                            </strong>
                            <strong>
                                <u>服务</u>
                            </strong>
                            <strong>
                                <u>”</u>
                            </strong>
                            <strong>
                                <u>功能进行增删、更新、调整，甚至取消本服务类型的权利。具体功能变更以您使用服务时，</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>相关页面的展示为准。</u>
                            </strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>四、信息收集及授权使用</p>
                        <p>&nbsp;</p>
                        <p>4.1 信息收集：在您使用本服务过程中，创孵猫将收集您在使用本服务时主动提供的或因为使用服务而产生的信息及材料，用以实现协助您完成向接入服务商提交备案申请的服务目的，包括：</p>
                        <p>4.1.1 个人信息：主体负责人及小程序负责人的姓名、身份证件号码、身份证件原件照片、人脸图像或视频、手机号码、固定电话、联系地址、电子邮件、职业状况、手持身份证件照片等。</p>
                        <p>4.1.2 单位信息：单位名称、单位证件号码、住所、通信地址、投资者或上级主管、单位证件原件照片、 手持单位证件照片等。</p>
                        <p>4.1.3 产品信息：产品类型、产品账号、产品验证信息、机房所在地等。</p>
                        <p>4.1.4 小程序信息：小程序名称、小程序ID、小程序服务内容、小程序语言、前置或专项审批内容类型、服务类型。</p>
                        <p>4.1.5 其他信息：与您签署的各项协议等。</p>
                        <p>
                            4.2
                            您的以上信息将提供给创孵猫。服务过程中，创孵猫工作人员将与您联系进行具体沟通并为您提供相关人工服务。您进行后续操作的行为将被视为创孵猫及其工作人员已获得了您的同意与授权使用以上信息为您提供服务，该等授权在本服务期间持续有效。
                        </p>
                        <p>
                            4.3
                            创孵猫将采取安全保密措施保护您提供的信息和材料。非经您同意，不向任何第三方披露、转让、使用或出售，亦不会用于备案之外的任何目的。
                            <strong>
                                <u>但以下情形除外：</u>
                            </strong>
                        </p>
                        <p>4.3.1 按照法律相关规定，申请备案时需要提供给接入服务商、备案审核机关通信管理局及工信部的信息、证件及其他材料；</p>
                        <p>4.3.2 非由于创孵猫的原因已经为公众所知的；</p>
                        <p>4.3.3 由于创孵猫以外其他渠道被他人获知的信息，这些渠道并不受保密义务的限制；</p>
                        <p>4.3.4 由于法律的适用、法院或其他国家有权机关的要求而披露的信息；</p>
                        <p>4.3.5 第三方向创孵猫提起侵权投诉后，您承认投诉成立、或您无理由不做回应、或创孵猫通过谨慎判断认为投诉成立后，投诉方为后续维权要求创孵猫提供您的信息的；</p>
                        <p>4.3.6 符合《创孵猫代备案服务信息收集及使用声明》的其他可披露情形的。</p>
                        <p>&nbsp;</p>
                        <p>五、服务使用规范</p>
                        <p>&nbsp;</p>
                        <p>
                            5.1
                            <strong>
                                <u>在使用本服务之前，您需以您自身名义开设</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>会员账号并完成实名认证。在注册账号时，您必须确保所提供的信息真实、准确、完整。若因未满足上述要求导致无法使用服务，责任由您自行承担。您的</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>会员账号对于使用</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>代备案服务至关重要，故您需妥善保管账号及密码，并独自负责账号和密码的安全性。您应理解且同意，您在</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>账号下的任何操作均视为您本人的行为，因此，您应对该账号下的一切交易、行为和纠纷承担相应的后果和责任。</u>
                            </strong>
                        </p>
                        <p>
                            5.2
                            <strong>
                                <u>您郑重承诺并确保遵守《</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>代备案服务协议》的所有条款，以及遵守</u>
                            </strong>
                            <strong>
                                <u>创孵猫页面</u>
                            </strong>
                            <strong>
                                <u>公示的备案规则。</u>
                            </strong>
                        </p>
                        <p>5.3 您必须理解并保证：</p>
                        <p>
                            5.3.1 在使用本服务过程中所提供的一切信息和材料的真实性和准确性，均由您负有责任。
                            <strong>
                                <u>如因您的故意或过失导致信息错误，您应独立承担全部责任和后果。</u>
                            </strong>
                        </p>
                        <p>
                            5.3.2
                            <strong>
                                <u>您需要向</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>提供完整、准确、可靠的联系方式，包括但不限于手机号码、电子邮件、联系地址或其他可供联系的详细信息。如在服务过程中您的联系方式发生变化，您务必及时通知</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>，以确保信息和文件传达顺畅。由于联系方式不准确所导致的任何后果，均由您独自承担。</u>
                            </strong>
                        </p>
                        <p>
                            <strong>
                                <u>5.3.3 若您在使用本服务时涉及第三方信息和证件，您应确保已充分告知该第三方用途，并获得其合法授权。</u>
                            </strong>
                        </p>
                        <p>
                            5.4
                            您有责任积极配合创孵猫提供相关服务。您同意按照创孵猫的指引，在规定的时间和要求内，及时、准确地提供并确认相关信息和材料。
                            <strong>
                                <u>如因您未及时、不准确地提供信息或材料，或未按照</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>要求修改、补正或确认信息和材料，您需自行承担责任及后果（包括但不限于办理时间延期、申请被驳回等）。</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>有权终止本次服务。</u>
                            </strong>
                        </p>
                        <p>5.5 您理解并保证：</p>
                        <p>
                            5.5.1
                            在使用本服务过程中，您应始终遵守国家法律、法规等相关规定，不得违反社会公共利益或道德规范，不得侵害他人的合法权益，同时，不得违反本服务协议的约定。
                            <strong>
                                <u>如您违反上述承诺，</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>有权随时终止本服务，您需以自己名义独立承担全部法律责任，并确保</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>免受任何损失。如因您使用本服务的行为给</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>造成损失，您应承担全部赔偿责任。</u>
                            </strong>
                        </p>
                        <p>
                            5.5.2
                            创孵猫仅为您提供咨询建议，并代为向接入服务商递交您的申请材料。这一行为不得视为知域互联对您所提供材料内容的认可、保证或担保。
                            <strong>
                                <u>您应对所提供的材料内容负完全独立的责任。如材料内容不真实、不准确，或存在其他不当内容，您需独立承担一切法律责任，并保证</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>不因此被任何第三方追究责任或受到任何其他损害。如此造成</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>损失，您需承担全部赔偿责任。</u>
                            </strong>
                        </p>
                        <p>
                            5.6
                            创孵猫不对您使用本服务的意图进行审查，但您所采取的行动不得使创孵猫陷入不利或纠纷之中。
                            <strong>
                                <u>如果因您使用本服务的行为给</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>造成损失，您需承担相应的责任。</u>
                            </strong>
                        </p>
                        <p>六、服务费用</p>
                        <p>&nbsp;</p>
                        <p>6.1 服务费用按照创孵猫订购页面所展示的最终价格为准，显示的价格已包含税金。您需根据订单价格进行支付，此费用已涵盖您向创孵猫支付的全部服务费用。</p>
                        <p>6.2 您可以基于已支付的服务费用向创孵猫申请相应金额的发票。</p>
                        <p>
                            6.3
                            创孵猫将在创孵猫的相关页面公布有关收费标准和缴费要求，并保留根据业务需要、市场变化等原因进行上述标准和要求调整的权利。根据当时的市场状况和自身经营政策等因素，创孵猫也可能会制定不同的服务收费标准、优惠和促销政策等。
                        </p>
                        <p>
                            6.4
                            <strong>
                                <u>若您对</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>订购页面显示的本服务价格、收费标准、费用结算等内容存在异议，应及时书面通知</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>，并停止使用</u>
                            </strong>
                            <strong>
                                <u>创孵猫备案</u>
                            </strong>
                            <strong>
                                <u>服务。否则，将被视为您认可</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>订购页面显示的价格、收费标准和结算事项，双方应根据此进行费用的结算和支付。</u>
                            </strong>
                        </p>
                        <p>6.5 创孵猫可能会定期或不定期推出优惠活动或政策。您需理解并确认：</p>
                        <p>6.5.1 此类优惠活动或政策均为创孵猫在正常服务价格之外提供的一次性特别优惠，创孵猫不保证您一定可以享受这些特别优惠。</p>
                        <p>6.5.2 在优惠活动或优惠政策期间购买本服务的用户可以享受特别优惠，但已经购买的服务不会因此获得差价退还。</p>
                        <p>6.5.3 通常情况下，除非创孵猫明确书面说明，多种优惠不可同时叠加适用。</p>
                        <p>
                            6.6
                            <strong>
                                <u>您同意，因收费标准调整等引发的任何纠纷，除非法律有明确规定，否则</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>保留免于诉讼或仲裁的权利。</u>
                            </strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>七、创孵猫服务承诺</p>
                        <p>&nbsp;</p>
                        <p>7.1 创孵猫将独立为您提供本协议约定的服务内容，协助您完成小程序备案申请提交至接入服务商的过程。</p>
                        <p>
                            7.2
                            您理解并同意，
                            <strong>
                                <u>在任何情况下，</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>均不会对备案通过接入服务商</u>
                            </strong>
                            <strong>
                                <u>复审</u>
                            </strong>
                            <strong>
                                <u>做出保证。备案是否通过接入服务商</u>
                            </strong>
                            <strong>
                                <u>复审</u>
                            </strong>
                            <strong>
                                <u>取决于您的备案申请材料是否符合法律法规政策和《</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>代备案服务协议》的相关规定，并由接入服务商审查后决定。</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>仅代表您向接入服务商提交备案申请，不以任何明示或暗示的方式保证您的备案申请一定会通过接入服务商初审。</u>
                            </strong>对于备案申请是否通过接入服务商初审，创孵猫不承担法律规定之外的责任。
                        </p>
                        <p>
                            7.3
                            您理解并同意，在任何情况下，
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>均不会对备案成功做出保证。备案是否成功取决于您的备案申请材料是否符合法律法规政策的相关规定，并由各省、自治区、直辖市通信管理局依法审查后决定。</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>仅代表您向接入服务商提交备案申请，不以任何明示或暗示的方式保证您的备案一定会成功或持续有效。</u>
                            </strong>对于备案是否成功，或备案成功后是否持续有效，创孵猫不承担法律规定之外的责任。
                        </p>
                        <p>
                            7.4
                            创孵猫有权对您提供的信息和材料进行审核，并要求您进行修改、补正或确认。在您未按要求进行修改、补正或确认之前，创孵猫不会向接入服务商提交您的备案申请。如果您未能在规定时间内按要求进行修改、补正或确认，创孵猫有权退回您的备案申请并终止本次服务。
                            <strong>
                                <u>如果因为您提供的信息或材料不符合要求，或未能及时进行修改、补正或确认，由您自行承担全部责任和后果。</u>
                            </strong>
                        </p>
                        <p>
                            7.5
                            您理解并同意，
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>有权根据审慎判断，对存在恶意使用本服务、扰乱业务秩序、多次违法违规等行为的用户账号或其关联账号采取限制使用本服务、冻结账号等措施。</u>
                            </strong>
                        </p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>知识产权</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                            8.1 在本服务协议项下一方向另一方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于 提供一方或其合法权利人所有；除提供方或合法权利人明示同意外，另一方无权复制、传播、转让、许可
                            或提供他人使用上述知识成果，否则应承担相应的责任。
                        </p>
                        <p>8.2 您应保证提交给创孵猫的信息和材料、对本服务的使用以及使用本服务所产生的成果，未侵犯任何 第三方的合法权益。创孵猫应保证向您提供的服务未侵犯任何第三方的合法权益。</p>
                        <p>
                            8.3 如果第三方机构或个人对您使用本服务过程中的知识产权的归属提出质疑或投诉，您和创孵猫均有责任出具相关知识产权证明材料，并配合对方的相关投诉处理工作。对于因此引起的索赔、诉讼或可能向
                            其提起诉讼，违约方应负责解决，承担费用和损失，以及使另一方免责。
                        </p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>不可抗力及责任承担</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                            9.1 因不可抗力或者其他意外事件，使得本服务履行不可能、不必要或者无意义的，遭受不可抗力、意外 事件的一方不承担责任。“不可抗力、意外事件”是指不能预见、不能克服并不能避免且对一方或双方当事
                            人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动 乱、政府行为、电信主干线路中断等。
                        </p>
                        <p>
                            9.2
                            <strong>
                                <u>鉴于互联网的特殊性，黑客攻击、互联网连通中断或者系统故障、电信部门原因造成的连通中断等非</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>原因给您造成的损失，除另有生效法律文书规定外，</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>不承担责任。</u>
                            </strong>
                        </p>
                        <p>
                            9.3
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>秉承着专业谨慎的态度为您提供服务，若由于</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>
                                    的过错造成本服务无法履行，知域
                                    互联的责任上限为返还您已支付的本次服务的费用。
                                </u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>
                                    不对您的其他直接或间接损失、商机的
                                    消失、利润的损失、侵权、其他无形损失及基于您与他人签署的合同而遭受的损失承担责任。
                                </u>
                            </strong>
                        </p>
                        <p>
                            9.4
                            <strong>
                                <u>您应承担违反本服务协议而给</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>造成的损失。</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>
                                    未对违约采取措施不能成为您违约或
                                    者抗辩的理由和解释。
                                </u>
                            </strong>
                        </p>
                        <p>9.5 本服务协议对责任承担另有规定的，从其规定。</p>
                        <p>&nbsp;</p>
                        <p>十、适用法律与争议解决</p>
                        <p>&nbsp;</p>
                        <p>10.1 本服务协议的订立、执行、解释及争议解决均应依据中华人民共和国内地（大陆）地区的法律。</p>
                        <p>10.2 您明确理解并同意，当您与创孵猫之间因创孵猫为您提供的创孵猫备案服务而产生争议时，双方应首先通过友好协商的方式解决。</p>
                        <p>
                            10.3
                            <strong>
                                <u>若协商未能达成一致，双方同意将争议提交至</u>
                            </strong>
                            <strong>
                                <u>创孵猫</u>
                            </strong>
                            <strong>
                                <u>主要经营地具有管辖权的人民法院进行诉讼解决。</u>
                            </strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>十一、其他</p>
                        <p>&nbsp;</p>
                        <p>11.1 创孵猫有权在提前通知您的情况下，将本服务协议的全部或部分权利义务转移给创孵猫的关联公司。</p>
                        <p>11.2 若本服务协议与双方先前签署的有关条款或创孵猫的相关陈述存在不一致或抵触之处，应以本服务协议为准。</p>
                        <p>11.3 若本服务协议的任一条款被视为废止、无效或不可执行，该条款应视为可分割的，且不影响本服务协议其余条款的有效性和可执行性。</p>
                        <p>11.4 本服务协议中关于保证、保密、知识产权、违约责任、法律适用及争议解决等条款，不因本服务协议的终止而失效。</p>
                    </div>
                </div>-->
                <div class="reviewing bgf">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckDefault1" v-model="ckOne" />
                        <label class="form-check-label reviewing-lab" for="flexCheckDefault1">
                            我已阅读并同意
                            <a href="#" @click.prevent="goPage('agreement')">《创孵猫代理备案服务协议》</a>
                            <a href="#" @click.prevent="goPage('statement')">《创孵猫代理备案服务信息收集及使用声明》</a>及同时授权本账号相关信息给创孵猫用于开展服务
                        </label>
                    </div>
                </div>
                <div class="price-area bgf">
                    <div class="price">
                        ￥
                        <span>{{sel_price}}</span>
                    </div>
                    <button type="submit" :class="ckOne == true ? 'trueBg':''">立即支付</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fromClass: false, //from表单验证样式
            typeList: [], //性质列表
            type: 1, //默认性质类型
            userName: '', //用户名
            appName: '', //小程序名称
            code: '', //推荐码
            set_meal: [], //现有套餐
            radioVal: 1, //默认套餐
            ckOne: false, //是否同意声明
            inputs: [], //需要判断是否为空的input框
            user: '',
            sel_price: '', //当前订单的价格
            count_price: '', //当前订单的价格(计算用)
            coupin: '', //优惠券ID
            coupinList: [], //优惠券列表
        }
    },
    methods: {
        // 选择套餐
        selectMeal(i, price) {
            this.radioVal = i
            this.sel_price = (price * 100) / 100
            // 计算用价格
            this.count_price = (this.set_meal[this.radioVal - 1].price * 100) / 100
            // 每次选择套餐后重新调用计算,得选择优惠券才执行
            if (this.coupin) {
                this.selectCoupin()
            }
        },
        // 提交表单
        submitForm() {
            // 调用检查表单的函数
            const emptyInput = this.checkEmptyInput()

            // 如果函数返回，则平移至为空的input框前
            if (emptyInput) {
                this.fromClass = true //给bs表单加上提醒样式的类名
                emptyInput.scrollIntoView({
                    behavior: 'smooth', //平移
                    block: 'center', //居中显示
                })
            } else {
                // 判断是否同意声明和协议
                if (this.ckOne) {
                    // 下一步操作
                    // 提交表单逻辑
                    this.fromClass = false //清除bs表单的提醒样式
                    // 创建订单
                    let _token = this.$cookies.get('mfm_token')
                    this.$axios
                        .post('/api/order/add', {
                            token: _token,
                            type: parseInt(this.type),
                            subject: this.userName,
                            app: this.appName,
                            code: this.code,
                            goods: parseInt(this.radioVal),
                            coupon: this.coupin,
                        })
                        .then((res) => {
                            console.log('res', res)
                            if (res.data.code == 200) {
                                console.log(res.data)
                                localStorage.setItem('orderIds', res.data.data.id) // 订单id存储
                                this.$router.push({ name: 'payments', params: { id: res.data.data.id } })
                            }
                            if (res.data.code == 500) {
                                console.log(res.data.msg)
                            }
                        })
                } else {
                    alert('请全部勾选声明协议')
                    return
                }
            }
        },
        // 检查是否有空的input框
        checkEmptyInput() {
            // 遍历检查是否有空的输入框
            for (let i = 0; i < this.inputs.length; i++) {
                if (this.inputs[i].value === '') {
                    return this.inputs[i]
                }
            }

            return null // 如果没有空的输入框
        },
        // 获取优惠券
        getCoupin() {
            let _token = this.$cookies.get('mfm_token')

            this.$axios
                .post('/api/coupon/list', {
                    token: _token,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.coupinList = res.data.data
                        console.log('this.coupinList', this.coupinList)
                    }
                    if (res.data.code == 500) {
                        console.log(res.data.msg)
                    }
                })
        },
        // 选择优惠券
        selectCoupin() {
            // 获取优惠券ID
            console.log('this.coupin', this.coupin)
            // 获取优惠券金额
            let selectedOption = this.coupinList.find((option) => option.detail.id === this.coupin)
            console.log('优惠券金额', selectedOption.detail.amount)
            let thisCoupin = Number(selectedOption.detail.amount)
            // 计算优惠后的价格
            let count_price = this.count_price
            let difference = count_price - thisCoupin
            let roundedResult = Math.ceil(difference * 100) / 100 // 向上取整保留两位小数
            roundedResult = roundedResult.toFixed(2) // 保留两位小数
            // 如果小数点后两位都是0，则不显示
            this.sel_price = Number(roundedResult) === parseInt(roundedResult) ? parseInt(roundedResult) : roundedResult
        },
        // 页面跳转
        goPage(path, i) {
            if (i) {
                this.$router.push({ path: `/${path}`, query: { id: i } })
                return
            }
            this.$router.push(`/${path}`)
        },
    },
    // 钩子
    mounted() {
        //dom获取所有input框
        this.inputs = document.querySelectorAll('.verify_input')
    },
    created() {
        // 企业性质
        const request1 = this.$axios.get('/api/type/list')

        // 商品列表
        const request2 = this.$axios.get('/api/goods/list')

        // 同时调用两个请求
        Promise.all([request1, request2]).then((res) => {
            // 两个请求现在都执行完成
            console.log('res', res)

            this.typeList = res[0].data.data
            this.set_meal = res[1].data.data
            this.sel_price = (res[1].data.data[0].price * 100) / 100
            this.count_price = (res[1].data.data[this.radioVal - 1].price * 100) / 100
        })

        // 获取优惠券
        this.getCoupin()

        let user = localStorage.getItem('mfm_user')
        this.user = user
    },
}
</script>

<style lang="scss" scoped>
.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
}

.was-validated .form-control:valid,
.was-validated .form-select:valid {
    border-color: var(--bs-border-color);
}
.was-validated .form-control:valid {
    background-image: none;
}
.was-validated .form-select:valid:not([multiple]):not([size]) {
    --bs-form-select-bg-icon: none;
}

.btn-outline-primary:hover {
    background: #eee;
    color: #000;
}

.line2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.bgf {
    background-color: #fff;
}

.page-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
}

.service-selection {
    margin: 10px 0;
    padding: 20px 16px;

    .area-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .list-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .service {
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            position: relative;
            padding: 20px 18px 14px;
            box-sizing: border-box;
            flex-basis: 47%;
            height: 150px;

            &.active {
                border-color: #f9902d;

                .title {
                    color: #f9902d;
                }
                .vice-title {
                    color: #f9902d;
                }
                .price {
                    color: #f9902d;
                }
                .ps-active {
                    display: block;
                }
            }

            &:last-child {
                &.active {
                    .ps-title {
                        display: block;
                    }
                }
            }

            .title {
                font-weight: bold;
                font-size: 18px;
            }
            .vice-title {
                color: #696969;
                font-size: 12px;
                margin: 6px 0 12px;
            }
            .price {
                color: #454545;
                font-weight: bold;
            }
            .ps-title {
                position: absolute;
                left: -1px;
                top: -12px;
                background-color: #f9be43;
                color: #fff;
                font-size: 14px;
                padding: 2px 8px;
                border-radius: 10px 0 10px 0;
                display: none;
            }
            .ps-active {
                position: absolute;
                right: -16px;
                bottom: -18px;
                display: none;
            }
        }
    }
}

.created-beian {
    .area-title {
        font-size: 18px;
        font-weight: bold;
        padding: 20px 0 20px 16px;
    }

    .info-area {
        padding: 0 16px 16px;

        .info-list {
            margin-bottom: 18px;
        }
    }

    .inp {
        border: none;
        font-size: 14px;
        background-color: #f8f8f8;
        padding: 16px;

        &::placeholder {
            color: #919191;
            font-size: 14px;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .lab {
        font-weight: bold;
        margin-bottom: 14px;
    }

    .tips {
        color: #696969;
        font-size: 14px;

        span {
            color: #3b3b3b;
            font-weight: bold;
        }
    }

    .reviewing {
        border-top: 1px solid #f8f8f8;
        padding: 16px;
    }

    .reviewing-lab {
        font-size: 14px;
        color: #484848 !important;
        line-height: 22px;

        a {
            text-decoration: none;
        }
    }

    .price-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        margin-top: 60px;

        .price {
            font-size: 18px;
            color: #f9902d;
            font-weight: bold;

            span {
                font-size: 26px;
                margin-left: -5px;
            }
        }

        button {
            background-color: #fcc592;
            border: none;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 1px;
            padding: 12px 30px;

            &.trueBg {
                background-color: #ffa842;
            }
        }
    }
}
</style>