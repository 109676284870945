<template>
    <div class="bg-box">
        <div class="header-title">备案服务</div>
        <div class="banner">
            <img src="@/assets/img/else/serve-banner.png" alt />
        </div>
        <div class="serve-list">
            <div class="serve">
                <div class="ps-title">代备案服务套餐</div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">按照小程序备案要求，指导填写有关备案材料并实名核验;</div>
                </div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">开展材料完备性、规范性、真实性审核;</div>
                </div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">审核通过后经小程序对应分发平台向小程序主办者住所所在地通信管理局提交备案申请。</div>
                </div>
            </div>
            <div class="serve">
                <div class="ps-title">代注册+备案服务套餐</div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">按照小程序备案要求，指导进行小程序的注册申请，填写和提交小程序名称、服务内容、主体负责人信息等材料;</div>
                </div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">反馈注册审核结果;</div>
                </div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">按照小程序备案要求，指导填写有关备案材料并实名核验;</div>
                </div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">开展材料完备性、规范性、真实性审核;</div>
                </div>
                <div class="rules">
                    <img src="@/assets/img/else/polygon.png" alt />
                    <div class="text">审核通过后经小程序对应分发平台向小程序主办者住所所在地通信管理局提交备案申请。</div>
                </div>
            </div>
        </div>
        <p class="btn-box mb-0">
            <router-link to="/myorder" class="w-100 beian-btn">立即申请备案</router-link>
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.bg-box {
    min-height: 100%;
    background: url(@/assets/img/else/bg.png) no-repeat;
    background-size: cover;
}

.header-title {
    height: 80px;
    line-height: 80px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 16px;
    box-sizing: border-box;

    img {
        width: 100%;
    }
}

.serve-list {
    padding: 0 16px;

    .serve {
        background: url(@/assets/img/else/serve-bg.png) #fff no-repeat;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 20px;
        position: relative;
        padding: 55px 58px 16px 17px;

        &:last-child {
            margin-bottom: 0;
        }

        .ps-title {
            position: absolute;
            left: 16px;
            top: 5px;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
        }

        .rules {
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                margin-right: 11px;
                margin-top: 5px;
            }

            .text {
                font-size: 14px;
            }
        }
    }
}

.btn-box {
    margin-top: 50px;

    .beian-btn {
        display: block;
        background-color: #ffa842;
        color: #fff;
        text-decoration: none;
        text-align: center;
        padding: 16px 0;
        font-weight: bold;
        letter-spacing: 2px;
    }
}
</style>